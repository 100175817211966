// import '../../node_modules/bootstrap/dist/js/bootstrap.bundle'
// import '../../assets/scripts/remove-paragraph-from-headers';
// import '../../node_modules/luminous-lightbox/dist/luminous-basic.css';
try {
  window.addEventListener('click', function(e){
    if (!document.querySelector('.navbar').contains(e.target)){
      navbarCollapse.classList.remove('show');
    }
  });

  const navLinks = document.querySelectorAll('.collapse-item');
  const navbarCollapse = document.querySelector('.navbar-collapse');

  navLinks.forEach((l) => {
    l.addEventListener('click', () => {
      navbarCollapse.classList.remove('show');
    })
  })
} catch (e) {
  console.log(e)
}
