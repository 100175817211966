
const btnTop = document.querySelector('.btn-top');

if(btnTop) {
  btnTop.addEventListener('click', () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  })

  document.addEventListener("scroll", (event) => {
    if(window.scrollY > 200) {
      btnTop.classList.remove('d-none')
    } else {
      btnTop.classList.add('d-none')
    }
  });
}