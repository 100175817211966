import AOS from '../../node_modules/aos/dist/aos';
import './home.scss';
import '../../components/navigation/navigation.jsx';
import '../../components/cookie-bar/cookie-bar.jsx';
import '../../components/btn-top/btn-top.jsx';
import Siema from 'siema';
import { Modal } from '../../node_modules/bootstrap';
import { LuminousGallery } from 'luminous-lightbox';

new LuminousGallery(document.querySelectorAll('.gallery-item'), {
    arrowNavigation: true,
});


AOS.init()
try {
    const siema = new Siema({
        selector: '.header-slider',
        loop: 'true'
    })

    document.querySelector('.header-section .btn-slide-left').addEventListener('click', () => siema.prev());
    document.querySelector('.header-section .btn-slide-right').addEventListener('click', () => siema.next());
} catch(e) {
    console.log(e)
}

try {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const teamMemberId = urlParams.get('team');
    const teamMemberModal = document.querySelector('#teacher-modal-' + teamMemberId);

    if(teamMemberModal) {
        const myModal = new Modal(teamMemberModal, {
            keyboard: false
        })

        myModal.show();
    }
} catch (e) {
    console.log(e);
}

try {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const teamMembers = document.querySelectorAll('.teachers-section article .member-title')


    // const teamNames = teamMembers && [...teamMembers]?.map(elem => elem.innerHTML?.toLowerCase()?.split(' ')?.join('_'));


    // console.log(teamNames);

    const teamParam = urlParams.get('team');

    console.log(teamParam, teamMembers);

    if(teamParam && teamMembers) {
        const nameElement = [...teamMembers].find(elem => {
            return elem.innerHTML.toLowerCase().includes(teamParam)
        });

        if(nameElement) {
            const id = nameElement.getAttribute('data-id');

            const modal = new Modal(document.getElementById("teacher-modal-" + id), {});

            modal.show();
        }
    }
} catch (e) {
    console.log(e);
}
